import React from 'react';

function Footer(props) {
    return (
        <div className='footer'>
            <h1>푸터 부분 입니다</h1>
        </div>
    );
}

export default Footer;